import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import { css, styled } from "../stitches.config";
import { withThemeContext } from "../redesign/components/StitchesThemeProvider";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

//eslint-disable-next-line
import AdStyles from "./styles/ad.scss";

import { AuthContext } from '../context/AuthContext';

// reklamy
// import Adform from "./Adslots/Adform";
// import Google from "./Adslots/Google";
import PlistaOutstream from "./Adslots/PlistaOutstream";
import FeatureFlagAdContainer from "./FeatureFlagAdContainer"

import ConditionalWrapper from "../helpers/ConditionalWrapper";

import { pushDataLayer, adSlotEnum, isEqualToKeyOrValue } from "../helpers"
import OnnetworkPlayer from "./OnnetworkPlayer";
import Lazyload from "react-lazyload";

import NoSSR from "../helpers/NoSSR";

const FireworkWrapper = React.lazy(() => import(/* webpackChunkName: "Adslots__FireworkWrapper" */"./Adslots/FireworkWrapper"));
const IkeaWrapper = React.lazy(() => import(/* webpackChunkName: "Adslots__IkeaWrapper" */"./Adslots/IkeaWrapper"))
const ACountdownSamsung = React.lazy(() => import(/* webpackChunkName: "composition-components__block__ACountdownSamsung" */"./composition-components/block/ACountdownSamsung"));

/**
 * id slotów reklamowych, z index.html
 */
const slot_mid = window.APP_SETTINGS.ads;

@inject("UIStore")
@observer
class Adslot extends Component {
  static contextType = AuthContext;
  static slotCounter = 0;

  slotNumber = 0;
  @observable
  emptyAdform = false;
  resizeObserver = null;
  prevAdHeight = 0;

  rerenderCounter = 0;
  isMaterialPartnera = false;

  lineItemId = null; //Identyfikator elementu zamówienia wyrenderowanej reklamy z rezerwacji.
  isActive = false;

  //Screening variables
  screeningObserver;
  static screeningObserveOptions = {
    attributes: true,
    attributeFilter: ['style', 'class']
  };
  hasScreening = false;
  isWallpaperScreening = false;
  isNatematScreening = false;

  constructor(props) {
    super(props);
    this.slotNumber = (++Adslot.slotCounter);
    this.mobxShouldComponentUpdate = this.shouldComponentUpdate;
    this.shouldComponentUpdate = this.shouldMoboxComponentUpdate;
    this.isMaterialPartnera = props.UIStore?.isMaterialPartnera;
  }

  pushEvent = async (event) => {
    const { name } = this.props
    // console.log(`pushing ${name}:${event}`)
    await window._gaq.push(["_trackEvent", "ADSLOT", name, event])
  }

  componentDidMount() {
    const { name } = this.props;
    const { googletag } = window;
    const isDesktop = window.innerWidth > 996;
    const slot = (slot_mid[name] || {});

    this.applyScreeningChecker();

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        const gptSlotId = event.slot.getSlotElementId()
        if (isEqualToKeyOrValue(gptSlotId, name, adSlotEnum)) {
          pushDataLayer({
            event: "analyticsEvent",
            eventCategory: "Adslots",
            eventAction: name,
            eventLabel: window.location.pathname,
            eventValue: undefined
          })
        }
      });
    })

    // ustawienia szarej zaślepki
    if (this?.adElement?.style) {
      const currentTopics = this.props.UIStore?.googleAdKeyWords?.topics || [];
      const isMaterialPartnera = (currentTopics.indexOf('material-partnera') >= 0);
      // console.log('Adslot googleAdKeyWords', isMaterialPartnera, name, slot, currentTopics);

      //Musi być timeout bo innaczej nie działa hydrate
      setTimeout(function () {
        this.wrapperElement?.classList?.remove("adslot--placeholder-desktop");
        this.wrapperElement?.classList?.remove("adslot--placeholder-mobile");

        let placeholderSize = null;
        if (isMaterialPartnera) {
          this.wrapperElement.classList.remove('adslot__ad-wrapper--fixed');
          placeholderSize = [0, 0];
        } else if (isDesktop && slot.placeholder_desktop) {
          placeholderSize = slot.placeholder_desktop.split('x');
        } else if (!isDesktop && slot.placeholder_mobile) {
          placeholderSize = slot.placeholder_mobile.split('x');
        }

        // console.log('placeholderSize', name, placeholderSize);
        if (placeholderSize && placeholderSize.length > 1 && (placeholderSize[0] > 0 && placeholderSize[1] > 0)) {
          this.wrapperElement?.classList?.add("adslot--active");
          this.isActive = true;
        }
      }.bind(this), 50);
    }

    if ("ResizeObserver" in window) {
      this.resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length || !entries[0].borderBoxSize || !entries[0].borderBoxSize.length || !this.lineItemId) {
            return;
          }

          const { borderBoxSize } = entries[0];
          const height = borderBoxSize[0].blockSize;

          if (this.prevAdHeight === height) {
            // console.log('same height, skipping', name)
            return;
          }

          if (this.prevAdHeight <= 30 && height > 30) {
            // console.log({ name, height })
            this.pushEvent("over30px");
            this.wrapperElement?.classList?.add("adslot--active");
            this.isActive = true;

            // #5266 monitorujemy czy coś nam nie wycina reklam, po ich pojawieniu się na ekranie
            setTimeout(() => {
              if (this.wrapperElement) {
                const isActive = this.wrapperElement.classList.contains("adslot--active");
                // console.log('ten seconds passed', name, isActive, this.wrapperElement);

                if (!isActive) {
                  this.pushEvent("disappeared")
                }
              }
            }, 10000)

            // console.log(`observed height is over 30, ${this.props.name}, ${height}`)
            if (name?.indexOf('_Billboard_1') >= 0 && (window.innerWidth > 996)) {
              setTimeout(() => this.checkScreening(), 500)
            }
          } else if (this.prevAdHeight > 30 && height <= 30) {
            this.wrapperElement?.classList?.remove("adslot--active");
            this.isActive = false;
            this.pushEvent("under30px");
          }

          this.prevAdHeight = height;
        })
      })

      this.adElement && this.resizeObserver.observe(this.adElement)
    }
  }

  componentWillUnmount() {
    this.resizeObserver?.disconnect(this.adElement);
    this.removeScreening();
  }

  componentDidCatch(error, info) {
    console.log('[ES] error w reklamie', error, info);
  }

  shouldMoboxComponentUpdate(props, ...next) {
    if (this.isMaterialPartnera)
      return false;

    const checkIsMaterialPartnera = props.UIStore?.isMaterialPartnera;
    if (checkIsMaterialPartnera)
      this.isMaterialPartnera = true;
    else
      return false;

    return this.mobxShouldComponentUpdate(props, ...next);
  }

  /* Screening */
  applyScreeningChecker() {
    const { googletag } = window;

    if (window.innerWidth < 996 || this.props.name?.indexOf('_Billboard_1') < 0)
      return;

    //Adslot with screening refresh handling
    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', this.handleScreeningAdslotRenderEnded);
    });

    //Apply screening checking
    this.screeningObserver = new MutationObserver(() => {
      this.checkScreening();
    });

    this.screeningObserver.observe(document.body, Adslot.screeningObserveOptions);
    this.screeningObserver.observe(document.documentElement, Adslot.screeningObserveOptions);

    this.checkScreening();
  }

  checkScreening() {
    const screeningRegex = /(adform)/gi;

    const htmlElement = document.documentElement;
    const bodyElement = document.body;

    let hasScreening = false;
    let isWallpaperScreening = false;
    let isNatematScreening = false;

    //Sprawdza czy do tagu <html> została dodana klasa ze skryptu reklamowego
    hasScreening |= Array.from(htmlElement.classList).filter(val => screeningRegex.test(val)).length > 0;

    //Sprawdza czy do body została dodana klasa screeningu
    isNatematScreening = bodyElement.classList.contains('screening_wallpaper');
    hasScreening |= isNatematScreening;

    //Sprawdza czy screening jest tapetą
    isWallpaperScreening = !!hasScreening && window.getComputedStyle(bodyElement).backgroundImage != 'none';
    isNatematScreening &= isWallpaperScreening;

    //Ustawiamy później, bo w tym samym czasie może już się odpalić checkScreening i psuje apply
    this.hasScreening = !!hasScreening;
    this.isWallpaperScreening = isWallpaperScreening;
    this.isNatematScreening = isNatematScreening;

    //Uruchamia skrypty dla screeningu z tapetą
    this.applyScreening();
  }

  static recalculateScreeningWallpaperPosition() {
    const bodyElement = document.body;
    const firstSlot = document.getElementsByClassName("adslot__ad-container");
    const nav = document.getElementsByClassName('nav-fixed');

    const navHeight = nav[0].offsetHeight;
    const belkaTopHeight = 75;
    const offsetTop = window.scrollY;
    if (firstSlot[0] && nav[0]) {
      bodyElement.style.transition = "all 0s";
      if (offsetTop >= (navHeight + belkaTopHeight) && bodyElement.classList.contains("belka-top--active")) {
        bodyElement.style.backgroundAttachment = '';
        bodyElement.style.setProperty("background-position", `center ${belkaTopHeight}px`, "important"); // belka top
      } else if (offsetTop >= navHeight && !bodyElement.classList.contains("belka-top--active")) {
        bodyElement.style.backgroundAttachment = '';
        bodyElement.style.setProperty("background-position", "center 0px", "important"); // 90px (zwykły offset) - 50px (offsetTop) = 40px
      } else {
        bodyElement.style.backgroundAttachment = 'scroll';
        bodyElement.style.backgroundPosition = '';
      }
    }
  }

  applyScreening() {
    if (!this.hasScreening)
      return;

    //Apply wallpaper screening
    if (this.wrapperElement && this.isWallpaperScreening) {

      const bodyElement = document.body;

      this.wrapperElement.classList.add("adslot--tapeta");
      bodyElement.classList.add("adslot--tapeta");
      this.wrapperElement.parentElement.classList.add("adslot--tapeta");

      //Auto move screening wallpaper
      const isFixedBackground = ['fixed', 'scroll'].includes(window.getComputedStyle(bodyElement).backgroundAttachment);
      if (isFixedBackground) {
        Adslot.recalculateScreeningWallpaperPosition();
        window.addEventListener("scroll", Adslot.recalculateScreeningWallpaperPosition);
      }

      this.screeningObserver.disconnect();
    }
  }

  removeScreening() {
    const { googletag } = window;

    //Remove screening listeners
    googletag.cmd.push(() => {
      googletag.pubads().removeEventListener('slotRenderEnded', this.handleScreeningAdslotRenderEnded);
    });

    if (!this.hasScreening)
      return;

    window.removeEventListener("scroll", Adslot.recalculateScreeningWallpaperPosition);

    const bodyElement = document.body;

    //Clear wallpaper screening
    if (this.wrapperElement) {
      this.wrapperElement.classList.remove("adslot--tapeta");
      this.wrapperElement.parentElement.classList.remove("adslot--tapeta");
    }
    bodyElement.classList.remove("adslot--tapeta");
    bodyElement.removeAttribute('style');

    //Need to set in try catch to do not crash react
    try {

      if (this.isNatematScreening)
        window?.screeningRemoveBg?.();
    } catch { }

    //Clear gntScreening
    const gntScreening = document.getElementById('gnt-screening');
    gntScreening && (gntScreening.innerHTML = "");

    this.screeningObserver?.disconnect();

    //Reset screening values
    this.isWallpaperScreening = false;
    this.hasScreening = false;
    this.isNatematScreening = false;
  }

  handleScreeningAdslotRenderEnded = function (event) {
    if (!this.hasScreening)
      return;

    const { name: adSlotName } = this.props;
    const gptSlotId = event.slot.getSlotElementId();

    if (gptSlotId != adSlotName)
      return;

    //Nie usuwa screeningu przy pierwszym załadowaniu AdSlota
    const isFirstRender = !this.lineItemId;
    this.lineItemId = event.lineItemId;

    if (event.isEmpty || isFirstRender)
      return;

    //Refresh adSlot screening config
    this.removeScreening();
    this.applyScreeningChecker();
  }.bind(this); //Bind object context on hooks

  /**
   * monitoruje, czy slot się zapełni czy nie
   * @deprecated
   */
  monitorAdformSlot(mid) {
    const adformtag = window.adformtag || [];
    const adslot = this;

    // console.log('NT:', 'monitorAdformSlot', mid);

    adformtag.push(function () {
      adformtag.onLoaded(mid, function (adItem) {
        // console.log('NT:', 'aditem', adItem);
        if (adItem.isEmpty) {
          // console.log('NT:', mid, 'no ad');
          adslot.emptyAdform = true;
        } else {
          // console.log('NT:', mid, 'has ad, add label');
        }
      });
    });
  }

  //GetPlaceholder style
  static getPlaceholderSettings(slot) {
    let placeholderStyle = {};
    let onDesktop = false, onMobile = false;

    if (slot.placeholder_desktop) {
      const [width, height] = slot.placeholder_desktop.split('x');
      placeholderStyle['--placeholder-desktop-width'] = `${width}px`;
      placeholderStyle['--placeholder-desktop-height'] = `${height}px`;
      onDesktop = true;
    }

    if (slot.placeholder_mobile) {
      const [width, height] = slot.placeholder_mobile.split('x');
      placeholderStyle['--placeholder-mobile-width'] = `${width}px`;
      placeholderStyle['--placeholder-mobile-height'] = `${height}px`;
      onMobile = true;
    }

    return {
      style: placeholderStyle,
      onDesktop, onMobile
    };
  }

  render() {
    const isDesktop = window.innerWidth > 996;
    // const [{ user }] = useAuth();
    const authContext = this.context;
    const user = ((Array.isArray(authContext) && authContext[0]) ? authContext[0]?.user : undefined);

    this.rerenderCounter++;

    if (window.location.hash && window.location.hash === "#SHOW-ADSLOTS") {
      var style = {
        background: "red",
        textAlign: "center",
        padding: "10px",
        // marginTop: "10px",
        // marginBottom: "10px",
      };

      if (this.props.only === 'desktop') {
        style.background = '#FF0055';
      } else if (this.props.only === 'mobile') {
        style.background = '#FF5500';
      }
      return (
        <>
          {this.props.only && window.APP_SETTINGS.ads[this.props.name]?.firework && <Adslot name={`${this.props.name}_firework`} />}
          <div className="adslot__placeholder" style={style}>
            {this.props.only ? this.props.only + ' | ' : null}
            ADSLOT {this.slotNumber} - rerender {this.rerenderCounter}: {this.props.name}: {(slot_mid[this.props.name] ? JSON.stringify(slot_mid[this.props.name]) : 'SLOT_NOT_FOUND')}
          </div>
        </>
      );
    }

    var that = this;
    var slot = (slot_mid[this.props.name] || {});
    // console.log('ADSLOT', this.props.name, this.emptyAdform, slot);
    // console.log(this.props.name)
    var ad = [];
    if (user && user.valid) {
      return null;
    } else if (slot.disabled) {
      // slot is disabled
    } else if (slot.samsung) {
      ad.push(<ACountdownSamsung />)
    } else if (slot.ikea) {
      ad.push(<IkeaWrapper />)
    } else if (slot.type === "onnetwork" && !this.props.disableAdditionalOnnetworkPlayer) {
      ad.push(<OnnetworkPlayer mid={slot.sid} />)
    } else if (slot.firework && !this.props.disableFirework) {
      ad.push(<FireworkWrapper name={this.props.name} slot={slot} />)
    } else if (slot.plista_outstream) {
      // console.log("plista defined here:", this.props.name)
      ad.push(<PlistaOutstream publicKey={slot.plista_outstream} />);
    } else {
      //Default AdContainer
      ad.push(<FeatureFlagAdContainer key={this.props.name} name={this.props.name} only={this.props.only} />)
    }

    //Do not display ad when empty 
    if (ad.length == 0) {
      return null;
    }

    const placeholderSettings = Adslot.getPlaceholderSettings(slot);

    const result = (
      <ConditionalWrapper
        condition={this.props.name?.startsWith("ART_intext_")}
        wrapper={children => <aside>{children}</aside>}
      >
        <div className={
          "adslot__ad-container" +
          (this.isWallpaperScreening ? " adslot--tapeta" : "") +
          (this.props.only ? ` adslot--only-${this.props.only}` : "")
        }
          data-name={this.props.name}
        >
          <div
            ref={element => { this.wrapperElement = element; }}
            className={
              "adslot__ad-wrapper" + ` adslot__name--${this.props.name}` +
              ((slot.fixed === true || slot.placeholder === true) ? " adslot__ad-wrapper--fixed" : "") +
              (slot.gray === true ? " adslot__ad-wrapper--gray" : "") +
              (slot.block === true ? " adslot__ad-wrapper--block" : "") +
              (this.isActive ? " adslot--active" : "") +
              (this.isWallpaperScreening ? " adslot--tapeta" : "") +
              (placeholderSettings.onDesktop ? " adslot--placeholder-desktop" : "") +
              (placeholderSettings.onMobile ? " adslot--placeholder-mobile" : "")
            }
          >
            {slot.block || <div className="adslot__ad-label">
              Reklama. <span>{this.props.description}</span>
            </div>}
            <div
              className={"adslot__ad-container adslot__ad-container--" + window.BRAND}
              style={placeholderSettings.style}
              ref={el => this.adElement = el}
            >
              <NoSSR fallback={null}>
                {ad}
              </NoSSR>
            </div>
          </div>
        </div>
      </ConditionalWrapper>)

    if (slot.lazy === true) {
      return <LazyLoad height={300} offset={300}>{result}</LazyLoad>;
    } else {
      return result;
    }

  }
}
export default Adslot;